<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <ylomi-logo />
        </b-link>

        <b-card-title class="mb-1">
          Réinitialisation du mot de passe 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Renseignez les champs ci-dessous afin de réinitialiser votre mot de
          passe
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="applyResetPasswordAction"
          >
            <!-- password -->
            <b-form-group label="Code de récupération" label-for="code">
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="code"
              >
                <b-form-input
                  v-model="payload.code"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrer le code reçu par sms"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Nouveau mot de passe"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="payload.new_password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="Entrer le nouveau mot de passe"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmation du mot de passe"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="payload.cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="Retaper le mot de passe"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              :disabled="isLoading"
              block
              type="submit"
              variant="primary"
            >
              <div v-if="isLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Envoyer</span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Retour
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import YlomiLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";

export default {
  components: {
    YlomiLogo,
    BCard,
    BSpinner,

    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      payload: {
        code: "",
        new_password: "",
        cPassword: "",
      },
      // validation
      required,
      isLoading: false,
      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    ...mapActions("auth", ["resetPasswordAction"]),
    applyResetPasswordAction() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.resetPasswordAction(this.payload)
            .then((response) => {
              this.isLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );

              this.$router.push({ name: "login" });
            })
            .catch((error) => {
              if (error && error.response) {
                this.isLoading = false;
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "AlertTriangleIcon",
                      text: error.response.data.message,
                      variant: "danger",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
              } else {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "AlertTriangleIcon",
                      text: "Vérifiez votre connexion internet et réesayez!",
                      variant: "danger",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
